import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


const NavBar = ({ textColour }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const [isOpen, setIsOpen] = useState(false);

    const logoColor = (textColour === 'white' ? '/images/logo_white.png' : '/images/logo_primary.png');

    return (
        <nav className={`${textColour === 'white' ? 'text-white' : 'text-primary'} absolute z-10 py-6 ml-20 custom:ml-14 mt-4  mb-4`}>
            {isMobile ? (
                <div className="z-20">
                    <button onClick={() => setIsOpen(!isOpen)} className="fixed top-0 right-0 mt-6 mr-6 z-20">
                        <svg className="w-16 h-16 flex items-center justify-end text-secondary z-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                    {isOpen && (
                        <ul className="fixed top-0 left-0 w-screen h-screen z-30 bg-secondary flex flex-col items-center justify-center">
                            <li className="absolute top-0 right-0 mt-6 mr-10">
                                <button onClick={() => setIsOpen(false)} className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-16 h-16">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6">
                                <NavLink className="text-4xl lg:text-lg text-white" activeClassName="underline" to="/" exact>Home</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className="text-4xl lg:text-lg" activeClassName="underline" to="/about">About</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className="text-4xl lg:text-lg" activeClassName="underline" to="/counselling">Counselling</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className="text-4xl lg:text-lg" activeClassName="underline" to="/workshops">Workshops</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className="text-4xl lg:text-lg" activeClassName="underline" to="/supervision">Supervision</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className="text-4xl lg:text-lg" activeClassName="underline" to="/blog">blog</NavLink>
                            </li>
                            <li className="mb-16 mr-3 md:mr-6 text-white">
                                <NavLink className=" text-4xl lg:text-lg" activeClassName="underline" to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    )}
                </div>
            ) : (
                <ul className="flex ml-4 custom:text">
                    <li>
                        <Link className="text-3xl merriweather-regular w-24" activeClassName="underline" to="/" exact><img src={process.env.PUBLIC_URL + logoColor} alt="Logo" className="nav-logo" /></Link>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/" exact>Home</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/about" exact>About</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/counselling" exact>Counselling</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/workshops" exact>Workshops</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/supervision" exact>Supervision</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link" activeClassName="text-red" to="/blog" exact>Blog</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link_last" activeClassName="text-red" to="/contact" exact>Contact</NavLink>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default NavBar;