import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

const RichText = ({ content }) => {

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <strong className="blog-bold">{text}</strong>,
            [MARKS.ITALIC]: (text) => <em className="blog-italic">{text}</em>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="blog-paragraph">{children}</p>
            ),
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="blog-custom-heading-1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="blog-custom-heading-2">{children}</h2>
            ),
            [BLOCKS.UL_LIST]: (node, children) => (
                <ul className="blog-custom-list">{children}</ul>
            ),
            [BLOCKS.OL_LIST]: (node, children) => (
                <ol className="blog-custom-ordered-list">{children}</ol>
            ),
            [BLOCKS.QUOTE]: (node, children) => (
                <blockquote className="blog-custom-blockquote">{children}</blockquote>
            ),
            [BLOCKS.HYPERLINK]: (node, children) => (
                <a
                    href={node.data.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-link"
                >
                    {children}
                </a>
            ),
        },
    };

    if (!content) {
        return null;
    }

    return (<div className="w-4/5 lg:mx-auto  mx-auto">
        {documentToReactComponents(content, options)}
    </div>
    )
};

export default RichText;