import NavBar from "./NavBar";
import BlogPost from "./BlogPost";
import client from "../contentfulClient";
import Footer from "./Footer";
import { useState, useEffect } from "react";


const Blog = () => {

    const [content, setContent] = useState(null);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await client.getEntries({
                    content_type: 'blogPost',
                    order: '-fields.date',
                });
                setContent(response.items);
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        };

        fetchContent();
    }, []);

    if (!content) {
        // Render a loading state or placeholder until content is fetched.
        return <div>Loading...</div>;
    }

    const colours = ['bg-primary', 'bg-tertiary', 'bg-primary', 'bg-white'];
    const startColour = 'bg-primary';

    function getColour(index) {
        let startIndex = colours.indexOf(startColour);
        return colours[(startIndex + index) % colours.length];
    }
    
    return (
        <div className='bg-primary'>
            <NavBar textColour='white' />
            <section className='w-full mx-auto text-white relative pt-40'>
                <h1 className='title'>Heart Matters</h1>
                {
                    content.map((item, index) => (
                        <BlogPost key={index} content={item} colour={getColour(index)} />

                    ))
                }

            </section>
            <Footer textColour={'white'} bg={'primary'} />
        </div>
    )
}

export default Blog;