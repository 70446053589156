import RichText from "./RichText";

const BlogPost = ({ content, colour }) => {

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    function convertDate(date_str) {
        let temp_date;
        temp_date = date_str.split("-");
        return months[Number(temp_date[1]) - 1] + " " + temp_date[2] + ", " +  + temp_date[0];
    }

    function textColour() {
        if (colour === 'bg-white' || colour === 'bg-tertiary') {
            return 'text-primary'
        }
    }

    return (
        <div className={`lg:w-full lg:mx-auto open-sans mx-auto pb-20 pt-20 ${colour} ${textColour()}`}>
                <h3 className='blog-title'>{content.fields.title}</h3>
                <h4 className='blog-heading'>{content.fields.heading}</h4>
                <h4 className='blog-date'>{convertDate(content.fields.date.toString())}</h4>
                <img src={process.env.PUBLIC_URL + `https:${content.fields.image[0].fields.file.url}`} className="mx-auto mb-16 w-2/3 lg:w-2/3" alt={content.fields.image[0].fields.description} />
                <RichText content={content.fields.text} />
        </div>
    )
}

export default BlogPost;