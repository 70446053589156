import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = ({ textColour, bg }) => {
    const logoColor = (textColour === 'white' ? '/images/logo_white.png' : '/images/logo_primary.png');
    const linkStyle = bg === 'primary' ? 'border-b-2 border-primary mb-4 pb-1' :
    bg === 'secondary' ? 'border-b-2 border-secondary mb-4 pb-1' :
    bg === 'tertiary' ? 'border-b-2 border-tertiary mb-4 pb-1' :
    'border-b-2 border-white mb-4 pb-1'; // Default to white if none of the above
    return (
        <footer className={`${textColour === 'white' ? 'text-white' : 'text-primary'} flex flex-col lg:flex-row lg:justify-between justify-start lg:items-center items-start text-center py-4 lg:w-10/12 w-3/6 mx-auto`}>
            <img src={process.env.PUBLIC_URL + logoColor } alt="Logo" className="h-14 w-auto lg:mr-8 lg:mb-0 my-4" />
            <div className="flex lg:flex-row flex-col w-1/3 mt-6 lg:justify-center justify-start lg:items-center items-start">
                <div className="lg:mx-auto flex flex-col justify-start items-start w-auto ">
                    <NavLink to="/" exact className={linkStyle}>Home</NavLink>
                    <NavLink to="/about" className={linkStyle}>About</NavLink>
                </div>
                <div className="lg:mx-auto flex flex-col justify-start items-start w-auto">
                    <NavLink to="/counselling" className={linkStyle}>Counselling</NavLink>
                    <NavLink to="/workshops" className={linkStyle}>Workshops</NavLink>

                </div>
                <div className="lg:mx-auto flex flex-col justify-start items-start w-auto">
                    <NavLink className={linkStyle} to="/supervision">Supervision</NavLink>
                    <NavLink className={linkStyle} to="/blog">Blog</NavLink>
                </div>
                <div className="lg:mx-auto flex flex-col justify-start items-start w-auto">
                    <NavLink className={linkStyle} to="/contact">Contact</NavLink>
                    <p className={linkStyle}>♥︎ ♥︎ ♥︎</p>
                </div>
            </div>
            <div className="text-left mt-4 lg:mt-0">
                <a className="underline" href="mailto:debbiewang.phd@gmail.com">debbiewang.phd@gmail.com</a>
                <p className="mt-4">Cambridge, Ontario</p>

            </div>
        </footer>
    );
};

export default Footer;